import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KhtStoreService } from '../shared/kht-store.service';
import { Settings } from '../shared/settings';

@Component({
  selector: 'kht-kht-kreislauf-ac4',
  templateUrl: './kht-kreislauf-ac4.component.html',
  styleUrls: ['./kht-kreislauf-ac4.component.scss']
})
export class KhtKreislaufAc4Component implements OnInit {

  constructor(private route: ActivatedRoute, private khts: KhtStoreService, @Inject(DOCUMENT) private document: Document) { }

  pKreislauf: string;
  pLanguage: string;
  pVehicle: string;
  settings: Settings;
  kreislaufData: any;
  kreislaufDetail: any;
  videoFile: string;
  display: boolean;
  videoFiles: string[];
  videoID: number;
  statusNavigation: boolean;

  ngOnInit() {
    this.pKreislauf = 'ac4';
    this.route.paramMap.subscribe( params => {
      this.pLanguage = params.get('language');
      this.pVehicle = params.get('vehicle');
      this.khts.getKreislaufData(this.pLanguage, this.pKreislauf).subscribe(kreislaufDetail => this.kreislaufDetail = kreislaufDetail);
      this.khts.getSettings(this.pLanguage).subscribe( settings => this.settings = settings);
      this.statusNavigation = false;
    });

    this.display = true;

    this.document.documentElement.lang = this.pLanguage;
  }

  displayToggleNavi() {
    if (this.statusNavigation === false) {
      this.statusNavigation = true;
    } else {
      this.statusNavigation = false;
    }
  }

}
