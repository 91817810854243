import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KhtStoreService } from '../shared/kht-store.service';
import { Settings } from '../shared/settings';

@Component({
  selector: 'kht-kht-bauteil-detail',
  templateUrl: './kht-bauteil-detail.component.html',
  styleUrls: ['./kht-bauteil-detail.component.scss']
})
export class KhtBauteilDetailComponent implements OnInit {

  pKreislauf: string;
  pLanguage: string;
  pVehicle: string;
  pBauteil: string;
  positionTurn: number;
  slideStatus: boolean;
  kreislaufDetail: Array<any>;
  settings: Settings;
  activeZusatz: number;
  statusNavigation: boolean;
  displayExplo: boolean;
  displayZusatz: boolean;

  constructor(private route: ActivatedRoute, private khts: KhtStoreService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pKreislauf = params.get('kreislauf');
      this.pLanguage = params.get('language');
      this.pVehicle = params.get('vehicle');
      this.pBauteil = params.get('bauteil');
      this.khts.getKreislaufData(this.pLanguage, this.pKreislauf).subscribe(kreislaufDetail => this.kreislaufDetail = kreislaufDetail);
      this.khts.getSettings(this.pLanguage).subscribe(settings => this.settings = settings);
      this.displayZusatz = false;
      this.activeZusatz = 0;
      this.statusNavigation = false;
    });

    this.displayExplo = false;

    if (this.displayZusatz === true) {
      this.showZusatzDetails();
    }
    this.document.documentElement.lang = this.pLanguage;

  }

  showExplo() {
    this.displayExplo = true;
  }

  hideExplo() {
    this.displayExplo = false;
  }

  showZusatzDetails() {

    if ( this.activeZusatz === 0 ) {
      this.activeZusatz = 1;
      this.displayZusatz = true;
    } else {
      this.activeZusatz = 0;
      this.displayZusatz = false;
    }

  }

  displayToggleNavi() {
    if (this.statusNavigation === false) {
      this.statusNavigation = true;
    } else {
      this.statusNavigation = false;
    }
  }
}
