import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Navigation } from '../shared/navigation';
import { KhtStoreService } from './../shared/kht-store.service';
import { ActivatedRoute } from '@angular/router';
import { Settings } from '../shared/settings';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'kht-kht-bauteile',
  templateUrl: './kht-bauteile.component.html',
  styleUrls: ['./kht-bauteile.component.scss']
})
export class KhtBauteileComponent implements OnInit {

  kreislaeufe: Navigation[];
  pLanguage: string;
  pVehicle: string;
  settings: Settings;
  kreislaufDetail;
  allKreislaufData;
  statusNavigation: boolean;

  constructor(private route: ActivatedRoute, private khts: KhtStoreService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pLanguage = params.get('language');
      this.pVehicle = params.get('vehicle');
      this.khts.getAllKreislaufData(this.pLanguage, this.pVehicle).subscribe(allKreislaufData => this.allKreislaufData = allKreislaufData);
      this.khts.getSettings(this.pLanguage).subscribe(settings => this.settings = settings);
      this.statusNavigation = false;
    });
    this.document.documentElement.lang = this.pLanguage;
  }

  displayToggleNavi() {
    if (this.statusNavigation === false) {
      this.statusNavigation = true;
    } else {
      this.statusNavigation = false;
    }
  }

}
