import { Component, OnInit, Input, Inject } from '@angular/core';
import { Navigation } from '../shared/navigation';
import { KhtStoreService } from '../shared/kht-store.service';
import { ActivatedRoute } from '@angular/router';
import { Settings } from '../shared/settings';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'kht-kht-bauteile-overview',
  templateUrl: './kht-bauteile-overview.component.html',
  styleUrls: ['./kht-bauteile-overview.component.scss']
})
export class KhtBauteileOverviewComponent implements OnInit {

  abc: any[];
  kreislaeufe;
  pKreislauf: string;
  pLanguage: string;
  pVehicle: string;
  @Input() kreislauf;
  @Input() kreislaufData: any;

  constructor(private khts: KhtStoreService, private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pKreislauf = params.get('kreislauf');
      this.pLanguage = params.get('language');
      this.pVehicle = params.get('vehicle');
    });
    this.khts.getAll(this.pLanguage, this.pVehicle).subscribe(kreislaeufe => this.kreislaeufe = kreislaeufe);
    this.document.documentElement.lang = this.pLanguage;
  }

  manageZusatzDetail() {
    // Call Parent
    // https://angular.io/guide/inputs-outputs
  }

}
