import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KhtListComponent } from './kht-list/kht-list.component';
import { KhtListItemComponent } from './kht-list-item/kht-list-item.component';
import { KhtKreislaufComponent } from './kht-kreislauf/kht-kreislauf.component';
import { KhtBauteileOverviewComponent } from './kht-bauteile-overview/kht-bauteile-overview.component';
import { HomeComponent } from './home/home.component';
import { KhtKreislaufDynComponent } from './kht-kreislauf-dyn/kht-kreislauf-dyn.component';
import { KhtBauteilDetailComponent } from './kht-bauteil-detail/kht-bauteil-detail.component';
import { KhtBauteileComponent } from './kht-bauteile/kht-bauteile.component';
import { KhtKreislaufHvComponent } from './kht-kreislauf-hv/kht-kreislauf-hv.component';
import { KhtBauteilEc06AuswahlComponent } from './kht-bauteil-ec06-auswahl/kht-bauteil-ec06-auswahl.component';
import { CallbackPipe } from './callback.pipe';
import { KhtBauteilViewerComponent } from './kht-bauteil-viewer/kht-bauteil-viewer.component';
import { KhtLegalNoticeComponent } from './kht-legal-notice/kht-legal-notice.component';
import { KhtLanguagesComponent } from './kht-languages/kht-languages.component';
import { KhtSucheEingabeComponent } from './kht-suche-eingabe/kht-suche-eingabe.component';
import { KhtSucheResultsComponent } from './kht-suche-results/kht-suche-results.component';
import { KhtBauteileEc03AuswahlComponent } from './kht-bauteile-ec03-auswahl/kht-bauteile-ec03-auswahl.component';
import { KhtBauteilEc06LkwAuswahlComponent } from './kht-bauteil-ec06-lkw-auswahl/kht-bauteil-ec06-lkw-auswahl.component';
import { KhtBauteilDetailIcondComponent } from './kht-bauteil-detail-icond/kht-bauteil-detail-icond.component';
import { KhtKreislaufAc4Component } from './kht-kreislauf-ac4/kht-kreislauf-ac4.component';
import { KhtImprintComponent } from './kht-imprint/kht-imprint.component';
import { KhtKreislaufHv2Component } from './kht-kreislauf-hv2/kht-kreislauf-hv2.component';

@NgModule({
  declarations: [
    AppComponent,
    KhtListComponent,
    KhtListItemComponent,
    KhtKreislaufComponent,
    KhtBauteileOverviewComponent,
    HomeComponent,
    KhtKreislaufDynComponent,
    KhtBauteilDetailComponent,
    KhtBauteileComponent,
    KhtKreislaufHvComponent,
    KhtBauteilEc06AuswahlComponent,
    CallbackPipe,
    KhtBauteilViewerComponent,
    KhtLegalNoticeComponent,
    KhtLanguagesComponent,
    KhtSucheEingabeComponent,
    KhtSucheResultsComponent,
    KhtBauteileEc03AuswahlComponent,
    KhtBauteilEc06LkwAuswahlComponent,
    KhtBauteilDetailIcondComponent,
    KhtKreislaufAc4Component,
    KhtImprintComponent,
    KhtKreislaufHv2Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
