import { Component, Inject, OnInit } from '@angular/core';
import { Settings } from '../shared/settings';
import { ActivatedRoute } from '@angular/router';
import { KhtStoreService } from '../shared/kht-store.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'kht-kht-bauteil-ec06-auswahl',
  templateUrl: './kht-bauteil-ec06-auswahl.component.html',
  styleUrls: ['./kht-bauteil-ec06-auswahl.component.scss']
})
export class KhtBauteilEc06AuswahlComponent implements OnInit {

  pKreislauf: string;
  pLanguage: string;
  pVehicle: string;
  pBauteil: string;
  positionTurn: number;
  slideStatus: boolean;
  kreislaufDetail;
  settings: Settings;
  statusNavigation: boolean;

  constructor(private route: ActivatedRoute, private khts: KhtStoreService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pKreislauf = params.get('kreislauf');
      this.pLanguage = params.get('language');
      this.pVehicle = params.get('vehicle');
      this.pBauteil = 'ec06a';
      this.khts.getKreislaufData(this.pLanguage, this.pKreislauf).subscribe(kreislaufDetail => this.kreislaufDetail = kreislaufDetail);
      this.khts.getSettings(this.pLanguage).subscribe(settings => this.settings = settings);
      this.statusNavigation = false;
    });

    this.document.documentElement.lang = this.pLanguage;
  }

  displayToggleNavi() {
    if (this.statusNavigation === false) {
      this.statusNavigation = true;
    } else {
      this.statusNavigation = false;
    }
  }

}
