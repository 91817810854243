import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'kht-kht-suche-eingabe',
  templateUrl: './kht-suche-eingabe.component.html',
  styleUrls: ['./kht-suche-eingabe.component.scss']
})
export class KhtSucheEingabeComponent implements OnInit {

  sucheingabe: string;
  suchbegriff: string;
  pLanguage: string;

  constructor(private router: Router, private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pLanguage = params.get('language');
    });
    this.document.documentElement.lang = this.pLanguage;
  }

  startSearch() {
    if (this.suchbegriff === '' || this.suchbegriff === undefined) {
      return;
    }
    this.router.navigate([this.pLanguage + '/search/' + this.suchbegriff]);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.startSearch();
  }

  setSuchbegriff(val: string) {

    this.suchbegriff = val;

  }

}
