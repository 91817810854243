import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Navigation } from '../shared/navigation';
import { KhtStoreService } from './../shared/kht-store.service';
import { ActivatedRoute } from '@angular/router';
import { Settings } from '../shared/settings';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'kht-kht-list',
  templateUrl: './kht-list.component.html',
  styleUrls: ['./kht-list.component.scss']
})
export class KhtListComponent implements OnInit {

  kreislaeufe: Navigation[];
  pLanguage: string;
  pVehicle: string;
  settings: Settings;
  statusNavigation: boolean;
  @Output() showDetailsEvent = new EventEmitter<Navigation>();

  constructor(private route: ActivatedRoute, private khts: KhtStoreService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pLanguage = params.get('language');
      this.pVehicle = params.get('vehicle');
      this.khts.getAll(this.pLanguage, this.pVehicle).subscribe(kreislaeufe => this.kreislaeufe = kreislaeufe);
      this.khts.getSettings(this.pLanguage).subscribe(settings => this.settings = settings);
      this.statusNavigation = false;
    });
    this.document.documentElement.lang = this.pLanguage;
  }

  showKreislauf(kreislauf: Navigation) {
    this.showDetailsEvent.emit(kreislauf);
  }

  displayToggleNavi() {
    if (this.statusNavigation === false) {
      this.statusNavigation = true;
    } else {
      this.statusNavigation = false;
    }
  }

}
