import { KhtBauteilDetailComponent } from './kht-bauteil-detail/kht-bauteil-detail.component';
import { HomeComponent } from './home/home.component';
import { KhtBauteileOverviewComponent } from './kht-bauteile-overview/kht-bauteile-overview.component';
import { KhtListComponent } from './kht-list/kht-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KhtKreislaufDynComponent } from './kht-kreislauf-dyn/kht-kreislauf-dyn.component';
import { KhtBauteileComponent } from './kht-bauteile/kht-bauteile.component';
import { KhtKreislaufHvComponent } from './kht-kreislauf-hv/kht-kreislauf-hv.component';
import { KhtKreislaufHv2Component } from './kht-kreislauf-hv2/kht-kreislauf-hv2.component';
import { KhtBauteilEc06AuswahlComponent } from './kht-bauteil-ec06-auswahl/kht-bauteil-ec06-auswahl.component';
import { KhtBauteileEc03AuswahlComponent } from './kht-bauteile-ec03-auswahl/kht-bauteile-ec03-auswahl.component';
import { KhtBauteilEc06LkwAuswahlComponent } from './kht-bauteil-ec06-lkw-auswahl/kht-bauteil-ec06-lkw-auswahl.component';
import { KhtLegalNoticeComponent } from './kht-legal-notice/kht-legal-notice.component';
import { KhtBauteilViewerComponent } from './kht-bauteil-viewer/kht-bauteil-viewer.component';
import { KhtSucheResultsComponent } from './kht-suche-results/kht-suche-results.component';
import { KhtBauteilDetailIcondComponent } from './kht-bauteil-detail-icond/kht-bauteil-detail-icond.component';
import { KhtImprintComponent } from './kht-imprint/kht-imprint.component';

const routes: Routes = [
  { path: '', redirectTo: 'en/home', pathMatch: 'full'},
  { path: ':language', redirectTo: ':language/home', pathMatch: 'full'},
  { path: ':language/home', component: HomeComponent, pathMatch: 'full' },
  { path: ':language/legal-notice', component: KhtLegalNoticeComponent, pathMatch: 'full' },
  { path: ':language/imprint', component: KhtImprintComponent, pathMatch: 'full' },
  { path: ':language/search/:search', component: KhtSucheResultsComponent, pathMatch: 'full' },
  { path: ':language/:vehicle/turns', component: KhtListComponent},
  { path: 'testen', component: KhtBauteileOverviewComponent, pathMatch: 'full' },
  { path: ':language/:vehicle/components', component: KhtBauteileComponent },
  { path: ':language/:vehicle/components/:bauteil', component: KhtBauteilViewerComponent },
  { path: ':language/:vehicle/turn/hv', component: KhtKreislaufHvComponent },
  { path: ':language/:vehicle/turn/hv2', component: KhtKreislaufHv2Component },
  { path: ':language/:vehicle/turn/:kreislauf', component: KhtKreislaufDynComponent },
  { path: ':language/:vehicle/turn/:kreislauf/component/ec03', component: KhtBauteileEc03AuswahlComponent },
  { path: ':language/:vehicle/turn/:kreislauf/component/ec10', component: KhtBauteileEc03AuswahlComponent },
  { path: ':language/:vehicle/turn/:kreislauf/component/ec06a', component: KhtBauteilEc06AuswahlComponent },
  { path: ':language/:vehicle/turn/:kreislauf/component/ec06_lkw', component: KhtBauteilEc06LkwAuswahlComponent },
  { path: ':language/:vehicle/turn/:kreislauf/component/ac_icond', component: KhtBauteilDetailIcondComponent },
  { path: ':language/:vehicle/turn/:kreislauf/component/:bauteil', component: KhtBauteilDetailComponent },
  { path: '**', redirectTo: 'en/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes), RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
