import { Component, OnInit, Input } from '@angular/core';
import { Navigation } from '../shared/navigation';

declare var hmm: any;

@Component({
  selector: 'kht-kht-list-item',
  templateUrl: './kht-list-item.component.html',
  styleUrls: ['./kht-list-item.component.scss']
})
export class KhtListItemComponent implements OnInit {

  constructor() { }

  /**
   * <kht-kht-list-item *ngFor="let kreislauf of kreislaeufe" [kreislauf]="kreislauf"></kht-kht-list-item>
   * kreislauf: Navigation bezieht sich auf: [kreislauf]
   */
  @Input() kreislauf: Navigation;

  ngOnInit() { }

  machWas(e: any) {
    // console.log(e.target.alt);
    // alert('Klick');
    // hmm('Ich komme aus angular'); // Funktioniert! Aber mit Fehler in Angular
  }

}
