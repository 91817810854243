import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KhtStoreService } from '../shared/kht-store.service';
import { Settings } from '../shared/settings';

@Component({
  selector: 'kht-kht-bauteil-viewer',
  templateUrl: './kht-bauteil-viewer.component.html',
  styleUrls: ['./kht-bauteil-viewer.component.scss']
})
export class KhtBauteilViewerComponent implements OnInit {

  pLanguage: string;
  pVehicle: string;
  pBauteil: string;
  positionTurn: number;
  slideStatus: boolean;
  allKreislaufData;
  settings: Settings;
  activeZusatz: number;
  statusNavigation: boolean;
  displayExplo: boolean;
  displayZusatz: boolean;

  constructor(private route: ActivatedRoute, private khts: KhtStoreService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pLanguage = params.get('language');
      this.pVehicle = params.get('vehicle');
      this.pBauteil = params.get('bauteil');
      this.khts.getAllKreislaufData(this.pLanguage, this.pVehicle).subscribe(allKreislaufData => this.allKreislaufData = allKreislaufData);
      this.khts.getSettings(this.pLanguage).subscribe(settings => this.settings = settings);
      this.displayZusatz = false;
      this.activeZusatz = 0;
      this.statusNavigation = false;
    });

    this.displayExplo = false;

    this.document.documentElement.lang = this.pLanguage;

    if (this.displayZusatz === true) {
      this.showZusatzDetails();
    }

  }

  showExplo() {
    this.displayExplo = true;
    console.log('show');
  }

  hideExplo() {
    this.displayExplo = false;
    console.log('hide');
  }

  showZusatzDetails() {

    if ( this.activeZusatz === 0 ) {
      this.activeZusatz = 1;
      this.displayZusatz = true;
    } else {
      this.activeZusatz = 0;
      this.displayZusatz = false;
    }

  }

  displayToggleNavi() {
    if (this.statusNavigation === false) {
      this.statusNavigation = true;
    } else {
      this.statusNavigation = false;
    }
  }

}
