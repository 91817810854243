import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KhtStoreService } from '../shared/kht-store.service';
import { Settings } from '../shared/settings';

@Component({
  selector: 'kht-kht-suche-results',
  templateUrl: './kht-suche-results.component.html',
  styleUrls: ['./kht-suche-results.component.scss']
})
export class KhtSucheResultsComponent implements OnInit {

  settings: Settings;
  pLanguage: string;
  pSearch: string;
  statusNavigation: boolean;
  searchResults;

  constructor(private route: ActivatedRoute, private khts: KhtStoreService, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pLanguage = params.get('language');
      this.pSearch = params.get('search');
      this.khts.getSearchResults(this.pLanguage, this.pSearch).subscribe(searchResults => this.searchResults = searchResults);
      this.khts.getSettings(this.pLanguage).subscribe(settings => this.settings = settings);
      this.statusNavigation = false;
    });
    this.document.documentElement.lang = this.pLanguage;
  }

  displayToggleNavi() {
    if (this.statusNavigation === false) {
      this.statusNavigation = true;
    } else {
      this.statusNavigation = false;
    }
  }

}
