import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kht-kht-languages',
  templateUrl: './kht-languages.component.html',
  styleUrls: ['./kht-languages.component.scss']
})
export class KhtLanguagesComponent implements OnInit {

  @Input() urlPath: string;
  pKreislauf: string;
  pLanguage: string;
  pVehicle: string;

  constructor(private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pKreislauf = params.get('kreislauf');
      this.pLanguage = params.get('language');
      this.pVehicle = params.get('vehicle');
    });
    this.document.documentElement.lang = this.pLanguage;
  }

}
