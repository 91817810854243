import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Navigation } from '../shared/navigation';

@Component({
  selector: 'kht-kht-kreislauf',
  templateUrl: './kht-kreislauf.component.html',
  styleUrls: ['./kht-kreislauf.component.scss']
})
export class KhtKreislaufComponent implements OnInit {
  @Input() kreislauf: Navigation;
  @Output() showListEvent = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  getRating(num: number) {
    return new Array(num);
  }

  showBookList() {
    this.showListEvent.emit();
  }

}
