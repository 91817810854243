import { Component, OnInit, Input, Inject } from '@angular/core';
import { Navigation } from '../shared/navigation';
import { KhtStoreService } from '../shared/kht-store.service';
import { ActivatedRoute } from '@angular/router';
import { Settings } from '../shared/settings';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'kht-kht-legal-notice',
  templateUrl: './kht-legal-notice.component.html',
  styleUrls: ['./kht-legal-notice.component.scss']
})
export class KhtLegalNoticeComponent implements OnInit {

  abc: any[];
  legalNotice;
  pLanguage: string;
  settings: Settings;
  statusNavigation: boolean;

  constructor(private khts: KhtStoreService, private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.route.paramMap.subscribe( params => {
      this.pLanguage = params.get('language');
      this.khts.getLegalNoticeData(this.pLanguage).subscribe(legalNotice => this.legalNotice = legalNotice);
      this.khts.getSettings(this.pLanguage).subscribe( settings => this.settings = settings);
      this.statusNavigation = false;
    });
    this.document.documentElement.lang = this.pLanguage;
  }

  displayToggleNavi() {
    if (this.statusNavigation === false) {
      this.statusNavigation = true;
    } else {
      this.statusNavigation = false;
    }
  }

}
