import { Kreislauf } from './shared/kreislauf';
import { Component } from '@angular/core';
// import { Navigation } from './shared/navigation'
// import { type } from 'os';

type ViewState = 'list' | 'details';

@Component({
  selector: 'kht-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

}
